<template>
    <div class="page">
        <Header disconnect="true" />

        <main class="page__main">
            <div class="content">
                <div class="content__header">
                    <div class="content__icon">
                        <span class="icon icon--light"><img src="../assets/images/ic-member.svg" /></span>
                    </div>
                    <div class="content__title">
                        <h1 class="h5">Votre participation à Constances</h1>
                    </div>
                </div>
                <div class="content__body">
                    <div class="banner">
                        <p>Chers utilisateurs,</p>
                        <p>
                            Nous tenons à vous présenter nos excuses pour l'indisponibilité de notre site Internet au
                            cours des ces dernières semaines, et nous sommes sincèrement désolés pour la gêne
                            occasionnée.</p>
                        <p>Toutes vos rubriques sont à nouveau accessibles, dont l'espace personnel des
                            volontaires.</p>
                        <p>Nous vous remercions pour votre patience et votre fidélité !</p>
                        <p style="margin-bottom: 0;">L'équipe Constances</p>
                    </div>
                    <ProfileForm :profile-link="true" />
                    <AvailableQuestionnaires />
                    <CompleteQuestionnaires />
                    <AnalysisResults />
                    <DownloadPopup />
                    <!--AddressChangePopup /-->
                </div>
            </div>
        </main>

        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import ProfileForm from '@/components/ProfileForm.vue'
import AvailableQuestionnaires from '@/components/AvailableQuestionnaires.vue'
import CompleteQuestionnaires from '@/components/CompleteQuestionnaires.vue'
import AnalysisResults from '@/components/AnalysisResults.vue'
import DownloadPopup from '@/components/DownloadPopup.vue'
import AddressChangePopup from '@/components/AddressChangePopup.vue'

import Tools from '../tools'

export default {
    name: 'Dashboard',
    components: {
        Header,
        Footer,
        ProfileForm,
        AvailableQuestionnaires,
        CompleteQuestionnaires,
        AnalysisResults,
        DownloadPopup,
        AddressChangePopup,
    },
    mixins: [Tools],
    methods: {
        ...mapActions(['getQuestionnaires']),
    },
    async mounted() {
        await this.getQuestionnaires()
        ///this.initExtLinks()
    },
}
</script>

<style scoped>
.banner {
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.banner h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 1.5;
}

.banner p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
}
</style>
